import { DiaryContent } from "../../diaryStyleConfig";

export const Jan262022 = () => {
  return (
    <DiaryContent>
      <h3>Jan 26 2022</h3>
      <p>再過幾天就要放假，我的心已經放假了。</p>
      <p>我發現手上的專案的UIUX真的有夠難規劃的…</p>
    </DiaryContent>
  );
};
