import { DiaryContent } from "../../diaryStyleConfig";

export const Jan252022 = () => {
  return (
    <DiaryContent>
      <h3>Jan 25 2022</h3>
      <p>感覺晚上變冷了。去全聯的路上看到肥橘跟小花流浪貓，好想要餵牠們喔。</p>
      <p>我補買了夜用綿綿可是沒買一般的，我要記得再補貨。= =</p>
      <p>話說我的這個網站要不要來做個CICD好了？</p>
    </DiaryContent>
  );
};
