import AnimeNewsNetwork from "../styles/images/website/animenewsnetwork.webp";

export const animeList = [
  {
    title: "Anime News Network",
    link: "https://www.animenewsnetwork.com/",
    image: AnimeNewsNetwork,
    description: "",
  },
];
