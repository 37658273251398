export const routeList = [
  {
    to: "/",
    title: "首頁",
  },
  {
    to: "/links",
    title: "常逛",
  },
  {
    to: "/textLinks",
    title: "連結",
  },
  {
    to: "/blog",
    title: "廢文",
  },
  {
    to: "/diary",
    title: "日記",
  },
];
